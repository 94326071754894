import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PlainFullLayout from "components/templates/generic/layouts/PlainFullLayout";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
//import MDAvatarV2 from "components/atoms/MDAvatar";

import Modal from "@mui/material/Modal";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import FormField from "components/molecules/FormField";

import logoImage from "assets/images/logo-wide.png";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import RedirectAuth from "components/molecules/RedirectAuth";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useSignin } from "pages/authentication/hooks/useSignin";
import { useResetPassword } from "pages/authentication/hooks/useResetPassword";

import { defaultSignedInPath } from "models/navigate";

import TermsOfUse from "pages/authentication/signin/components/TermsOfUse";

function SignIn() {
  const { signin, error } = useSignin();
  const { sendPasswordResetEmail } = useResetPassword();
  const continueURL = window.location.href.replace(
    window.location.pathname,
    "/authentication/signin"
  );
  const [errorResetPassword, setErrorResetPassword] = useState(null);
  const [successResetPassword, setSuccessResetPassword] = useState(null);
  const msgSuccessResetPassword =
    "A link to reset your password has been emailed to the address provided. Please click the link in the email to complete the password reset process.";

  const { dispatch } = useAuthContext();
  const { retrieveDocs, updateDoc, createDoc, serverTimestamp } = useDocument();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || defaultSignedInPath;

  const [showDetails, setShowDetails] = useState(true);
  const handleMinimize = async () => {
    showDetails === true ? setShowDetails(false) : setShowDetails(true);
  };
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleShowResetPassword = async () => {
    showResetPassword === true
      ? setShowResetPassword(false)
      : setShowResetPassword(true);
  };

  const [open, setOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "5%",
    left: "5%",
    // transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <RedirectAuth>
      {error && <ErrorAlert message={error} />}
      {errorResetPassword && <ErrorAlert message={errorResetPassword} />}
      {successResetPassword && <SuccessAlert message={successResetPassword} />}
      <PlainFullLayout /*image={bgImage}*/>
        <MDBox
          sx={{ width: "100%", pt: 10 }}
          //mt={{ xs: -20, lg: -18 }}
          //px={1}
          //width="calc(100% - 2rem)"
          //mx="auto"
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card sx={{ backgroundColor: "rgb(250,250,250)" }}>
                {/* Logo*/}
                <MDBox
                  mt={-8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MDBox
                    bgColor="white"
                    px="0.4rem"
                    py="0.4rem"
                    borderRadius="xl"
                    border="1px solid lightgrey"
                    component="img"
                    src={logoImage}
                    alt="Brand"
                    width="14rem"
                  />
                </MDBox>

                {/* Project descriptions*/}
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={1}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Welcome to the CRADLE 2 IT PLATFORM
                  </MDTypography>

                  {showDetails === true ? (
                    <MDTypography
                      display="block"
                      variant="body3"
                      color="white"
                      my={1}
                      textAlign="justify"
                    >
                      Project descriptions ...
                    </MDTypography>
                  ) : (
                    ""
                  )}

                  <MDBox textAlign="right">
                    <MDButton
                      type="submit"
                      variant="text"
                      color="light"
                      size="small"
                      onClick={() => handleMinimize()}
                    >
                      {showDetails === true ? "hide ..." : "show ..."}
                    </MDButton>
                  </MDBox>
                </MDBox>

                {/* Prompt user to enter username & password */}
                <MDBox pt={1} pb={0} px={3}>
                  <MDTypography display="block" variant="body3" my={1}>
                    Enter your email and password to sign in.
                  </MDTypography>
                </MDBox>

                <MDBox pt={0} pb={0} px={3}>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: false,
                      toc: false,
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .required("Required")
                        .email("Invalid email address"),
                      password: Yup.string().required("Required"),
                      remember: Yup.boolean(),
                      toc: Yup.boolean().oneOf([true], "Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      const { user, roles } = await signin(
                        values.email,
                        values.password,
                        values.remember
                      );

                      if (user.uid) {
                        const retrievedMetadatas =
                          user.uid &&
                          (await retrieveDocs(`metadata/${user.uid}/session`));

                        const signedInStates = retrievedMetadatas?.map(
                          (metadata) => metadata.data.signedIn
                        );

                        // check any login at other place
                        if (signedInStates?.includes(true)) {
                          await Promise.all(
                            retrievedMetadatas.map(async (metadata) => {
                              await updateDoc(
                                `metadata/${user.uid}/session`,
                                metadata.id,
                                {
                                  flagSignOut: true,
                                  flaggedAt: serverTimestamp(),
                                }
                              );
                            })
                          );
                        }

                        const createdSession = await createDoc(
                          `metadata/${user.uid}/session`,
                          {
                            revokeTime: null,
                            signedIn: true,
                          }
                        );

                        dispatch({
                          type: "SIGNIN",
                          payload: {
                            user: user,
                            roles: roles,
                            sessionId: createdSession.id,
                          },
                        });

                        navigate(from, { replace: true });
                      }
                      // }

                      //        setSuccess("Sign in succeed.");
                      error && setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form>
                        <FormField
                          type="email"
                          label="Email"
                          name="email"
                          error={errors.email && touched.email}
                          success={values.email.length > 0 && !errors.email}
                        />
                        <FormField
                          type="password"
                          label="Password"
                          name="password"
                          error={errors.password && touched.password}
                          success={
                            values.password.length > 0 && !errors.password
                          }
                        />
                        <MDBox mt={-1} ml={-1}>
                          <FormField type="checkbox" label="" name="remember">
                            <MDTypography
                              variant="body3"
                              fontWeight="regular"
                              color="text"
                              sx={{
                                cursor: "pointer",
                                userSelect: "none",
                                ml: -1,
                              }}
                            >
                              &nbsp;&nbsp;Remember me
                            </MDTypography>
                          </FormField>
                        </MDBox>
                        <MDBox
                          display="flex"
                          alignItems="baseline"
                          mt={-2}
                          ml={-1}
                        >
                          <MDBox>
                            <FormField type="checkbox" label="" name="toc">
                              <MDTypography
                                variant="body3"
                                fontWeight="regular"
                                color="text"
                                sx={{
                                  cursor: "pointer",
                                  userSelect: "none",
                                  ml: -1,
                                }}
                              >
                                &nbsp;&nbsp;I agree to the
                              </MDTypography>
                            </FormField>
                          </MDBox>
                          <MDBox ml={-1}>
                            <MDButton
                              variant="text"
                              color="info"
                              size="small"
                              onClick={() => setOpen(true)}
                            >
                              terms and conditions.
                            </MDButton>
                          </MDBox>
                        </MDBox>
                        <MDBox mt={2} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                          >
                            sign in
                          </MDButton>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>

                <MDBox textAlign="right">
                  <MDButton
                    type="submit"
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => handleShowResetPassword()}
                  >
                    Forgot password?
                  </MDButton>
                </MDBox>
                {showResetPassword && (
                  <MDBox pt={3} pb={3} px={3}>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .required("Required")
                          .email("Invalid email address"),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        await sendPasswordResetEmail(values.email, continueURL);
                        setSuccessResetPassword(msgSuccessResetPassword);
                        setErrorResetPassword(null);
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                          <FormField
                            type="email"
                            label="Email"
                            name="email"
                            errorResetPassword={errors.email && touched.email}
                            successResetPassword={
                              values.email.length > 0 && !errors.email
                            }
                          />
                          <MDBox mt={3} mb={1}>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="success"
                              fullWidth
                            >
                              Send Password Reset Email
                            </MDButton>
                          </MDBox>
                        </Form>
                      )}
                    </Formik>
                  </MDBox>
                )}

                <MDBox mt={0} mb={2} textAlign="center">
                  <MDBox>
                    <MDTypography variant="caption" color="text" textGradient>
                      Don&apos;t have an account?{" "}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={-1}>
                    <MDTypography
                      variant="caption"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Please Consult Your Administrator
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox sx={style} overflow="auto">
            <MDBox mb={3}>
              <TermsOfUse></TermsOfUse>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="success"
                fullWidth
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </MDButton>
            </MDBox>
          </MDBox>
        </Modal>
      </PlainFullLayout>
    </RedirectAuth>
  );
}

export default SignIn;
